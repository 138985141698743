import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
const ImageWithSkeleton = (props:any) =>  {
        const {state,src,type
        } = props
        const [imageLoaded, setImageLoaded] = useState(false);
        return (
            <div style={{ width: "100%", borderRadius: "15px", overflow: "hidden" }}>
      {!imageLoaded && type === "destination" && (
        <Skeleton height={200} style={{ borderRadius: "15px" }} />
      )}
      {!imageLoaded && type === "offers" && (
        <Skeleton height={150} style={{ borderRadius: "15px" }} />
      )}
      {!imageLoaded && type === "blogs" && (
        <Skeleton height={350} style={{ borderRadius: "15px" }} />
      )}
       {!imageLoaded && type === "whereToGo" && (
        <Skeleton height={150} style={{ borderRadius: "15px" }} />
      )}

      <img
        src={src}
        onLoad={() => setImageLoaded(true)}
        style={{
          display: imageLoaded ? "block" : "none",
          width: "100%",
          borderRadius: "15px",
          filter: "brightness(0.7)",
        }}
        
        alt=""
      />
    </div>
            )
}
export default ImageWithSkeleton;

