import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "../../blocks/landingpage/assets/style.css"

const BGComponent = ({ oceanbackground }:any) => {
  const [backgroundLoaded, setBackgroundLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = oceanbackground;

    img.onload = () => {
      setBackgroundLoaded(true);
    };
  }, [oceanbackground]);

  return (
    <div>
      {!backgroundLoaded && (
        <Skeleton height={650} style={{ borderRadius: "15px", width: "100%" }} />
      )}

      {backgroundLoaded && (
        <div
        className="bgSectionHeader"
          style={{
            backgroundImage: `url(${oceanbackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            width: "100%",
            height: "650px", 
            opacity: 1, 
            position:"absolute"
          }}
        />
      )}
    </div>
  );
};

export default BGComponent;
