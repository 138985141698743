import React from "react";

import {
    Box,
    Button,
    Typography,
    // Customizable Area Start
    Grid,
    CardMedia,
    Card,
    List,
    ListItem,
    ListItemText
    // Customizable Area End


} from "@material-ui/core";
// Customizable Area Start
import { image1, image2, couponIcon } from "./assets";
import "../assets/style.css"
// Customizable Area End

import PackageDetailController, {
    Props,
} from "./PackageDetailController.web";
import PackageSearch from "./PackageSearch.web";

export default class PackageDetail extends PackageDetailController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {
        let dayPlan = [{ "id": "1", "value": "9 June, Sun" },
        { "id": "2", "value": "10 June, Mon" },
        { "id": "3", "value": "11 June, Tue" },
        { "id": "4", "value": "12 June, Wed" },
        { "id": "5", "value": "13 June, Thu" },
        { "id": "6", "value": "14 June, Fri" },
        { "id": "7", "value": "15 June, Sat" },
        ]
        const couponsData = [
            { id: 1, code: 'DOOLWA 30' },
            { id: 2, code: 'WELCOME 30' },
            { id: 3, code: 'FLY 20' },
            { id: 4, code: 'WELCOMEDOOLWA 20' },
            { id: 5, code: 'DOOLWA 10' }
        ];
        return (
            // Customizable Area Start
            <>
                <PackageSearch package={""} navigation={this.props.navigation} id={""} />
                <Box sx={{ padding: { xs: "10px 10px", sm: "10px 10px", md: "10px 30px", lg: "10px 60px" }, }}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div className="headTopDetail">
                                <Typography variant="h5" data-test-id="mainHeading" className="headingdetail">
                                    Goa delight Group Departure 2024
                                </Typography>

                            </div>

                        </Grid>
                    </Grid>
                    <Box className="boxShadowDetail">
                        <Grid spacing={2} container>
                            <Grid item xs={12} sm={12} md={4}>
                                <Card className="image-card">
                                    <CardMedia
                                        component="img"
                                        alt="Beach huts"
                                        image={image1}
                                        title="Beach huts"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Card className="image-card sub-image" style={{ marginBottom: "10px" }}>
                                    <CardMedia
                                        component="img"
                                        alt="Temple"
                                        image={image2}
                                        title="Temple"
                                    />
                                </Card>
                                <Card className="image-card sub-image">
                                    <CardMedia
                                        component="img"
                                        alt="Cityscape"
                                        image={image1}
                                        title="Cityscape"
                                    />
                                </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4}>
                                <Card className="image-card">
                                    <CardMedia
                                        component="img"
                                        alt="Beach huts"
                                        image={image1}
                                        title="Beach huts"
                                    />
                                </Card>
                            </Grid>
                        </Grid>
                        <Typography variant="body1" className="itinerary">
                            ITINERARY
                        </Typography>
                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={8}>
                            <Box className="boxShadowDetail">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={3}>
                                        <Box>
                                            <Typography data-test-id="dayPlan" style={{ fontSize: "24px", fontWeight: 700, padding: "15px 0", textAlign: "center" }}>7 Day Plan</Typography>
                                            <ul className="dayplanUl">
                                                {dayPlan.map((item: any) => (
                                                    <li style={{ background: item.id === "1" ? "rgba(23, 100, 160, 1)" : "rgb(233, 243, 252)", color: item.id === "1" ? "#fff" : "#000" }}
                                                        key={item.id}>{item.value}
                                                    </li>
                                                ))}

                                            </ul>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9}>
                                        <div style={{ position: "relative" }}>
                                            <Box sx={{ position: { xs: "relative", sm: "relative", md: "absolute" }, top: "11px", right: "0" }}>
                                                <Button variant="outlined" style={webStyleDetail.ButtonStyle1}>
                                                    6N/7D
                                                </Button>
                                                &nbsp;&nbsp;
                                                <Button variant="outlined" style={webStyleDetail.ButtonStyle2}>
                                                    Download PDF
                                                </Button>
                                            </Box>
                                            <Typography variant="h5" className="sectionTitle">
                                                <span style={webStyleDetail.ButtonStyle}> Day 1 - Calangute Beach</span>
                                            </Typography>
                                            <Typography variant="body1" className="textPrePackages">
                                                Baga Beach is one of the most popular beaches in Goa,  and shacks serving delicious Goan
                                                cuisine...
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}>

                                                    <img src={image1} className="image-cardMulti" title="dummy" />
                                                </Grid>

                                                <Grid item xs={12} sm={4}>

                                                    <img src={image2} className="image-cardMulti" title="Baga Beach goa" />
                                                </Grid>



                                                <Grid item xs={12} sm={4}>
                                                    <img src={image1} title="Baga Beach" className="image-cardMulti"  />
                                                </Grid>
                                            </Grid>
                                            <ul className="listDetailsUl">
                                                <li>Roundtrip Flights</li>
                                                <li>Selected Meals</li>
                                                <li>3 Star Hotel</li>
                                            </ul>
                                            <Typography variant="h5">
                                                <span style={webStyleDetail.ButtonStyle}> Day 2 - Calangute Beach</span>

                                            </Typography>
                                            <Typography variant="body1" className="textPrePackages">
                                                Often referred to as the "Queen of Beaches," Calangute Beach is the
                                                largest in North Goa and attracts tourists from all over the world...
                                            </Typography>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={4}>
                                                    <img className="image-cardMulti" src={image1}  title="Baga Beach" />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <img src={image1} className="image-cardMulti" title="Baga Beach" />
                                                </Grid>
                                                <Grid item xs={12} sm={4}>
                                                    <img title="Baga Beach" src={image1} className="image-cardMulti"  />
                                                </Grid>
                                            </Grid>




                                            <ul className="listDetailsUl">
                                            <li>Selected Meals</li>
                                                <li>3 Star Hotel</li>
                                                <li>Including Breakfast</li>
                                                <li>Dinner Meals</li>
                                                <li>Roundtrip Flights</li>
                                                <li>Airport Transfers</li>
                                               
                                            </ul>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Box className="boxShadowDetailCoupon">
                                <Box >
                                    <Box style={{ textAlign: "center" }}>
                                        <Typography style={{ fontSize: "24px", fontWeight: 700, padding: "15px 0" }}>$ 35,000/Adult</Typography>
                                        <Button
                                            type="submit"
                                            data-test-id="handleSubmitOrderMgmtss"
                                            style={{
                                                background: "rgba(23, 100, 160, 1)",
                                                borderRadius: "5px",
                                                padding: "10px 35px",
                                                marginTop: "10px", color: "#fff",
                                                fontSize: "16px", textTransform: "capitalize",
                                                fontWeight: 700
                                            }}
                                        >Proceed To Payment</Button>
                                    </Box>
                                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div style={{ width: "100%" }}>
                                            <Typography data-test-id="Coupons" variant="h6" style={{ padding: "20px 17px", fontSize: "16px", fontWeight: 700 }}>Coupons & Offers</Typography>
                                            <List>
                                                {couponsData.map((coupon, index) => (
                                                    <ListItem key={coupon.id} divider >
                                                        <img src={couponIcon} />&nbsp;&nbsp;
                                                        <ListItemText className="textCoupon" primary={coupon.code} />
                                                        <Button
                                                            variant="outlined"
                                                            style={{ color: "rgba(23, 100, 160, 1)", background: "none", border: "none" }}
                                                            data-test-id={`${"handleApplyCoupon"}${index}`}
                                                            onClick={() => this.handleApplyCoupon(coupon.code)}
                                                        >
                                                            <span style={webStyleDetail.textCouponApply}>
                                                                {this.state.appliedCoupon === coupon.code ? (
                                                                    <>
                                                                        APPLIED <span style={{ marginLeft: '10px', cursor: 'pointer' }}>&times;</span>
                                                                    </>
                                                                ) : (
                                                                    'APPLY'
                                                                )}
                                                            </span>

                                                        </Button>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
let webStyleDetail = {
    ButtonStyle: {
        display: "inline-block",
        padding: "10px",
        fontSize: "14px",
        FontWeight: 700,
        lineHeight: "18px",
        marginTop: "12px",
        marginBottom: "12px",
        color: "white",
        borderRadius: "0",
        background: "rgba(23, 100, 160, 1)"
    },
    ButtonStyle1: {
        fontSize: "14px",
        fontWeight: 700,
        color: "#000",
        background: "#fff",
        border: "1px solid rgb(23, 100, 160)"
    },
    ButtonStyle2: {

        fontSize: "14px",
        fontWeight: 700,
        color: "white",
        background: "rgba(23, 100, 160, 1)"
    },
    textCouponApply: {
        fontSize: "16px", fontWeight: 700
    }
}

// Customizable Area End

