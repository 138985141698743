import React from "react";
import FileCopyIcon from '@material-ui/icons/FileCopy';

const CopyText = (props:any) =>  {
        const {state,clickCopyFun
        } = props
        const copyFn = (text:any) => {
            navigator.clipboard.writeText(text).then(() => {
                clickCopyFun(true,"Coupon code copied to clipboard!")
            }).catch(err => {
                props.clickCopyFun(true,err)
            });
          };
        return (
            <li data-test-id="copyFn" style={{display:"flex", alignItems:"center"}} onClick={()=>copyFn(state.singleOfferData.data.attributes.offer_code)}><FileCopyIcon style={{marginLeft:"10px", cursor:"pointer"}} /></li>
        
            )
}
export default CopyText;

