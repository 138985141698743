import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { RefObject } from "react";
type DateRange = {
    selection: {
        startDate: Date;
        endDate: Date;
    };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    package:string;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    searchText: {
        state: string,
        country: string,
        flight: string,
        stateCode: string
    };
    filteredItems: any;
    inputSearch: string
    inputSearchTo: string
    anchorEl: Element | null;
    anchorElTo: Element | null;
    currentIndex: number;
    searchField: string;
    filteredItemsTo: any;
    toText: {
        state: string,
        country: string,
        flight: string,
        stateCode: string
    }
    startDate: Date | null;
    endDate: Date | null;
    isOpen: boolean;
    activePicker: string;
    setAnchorAi: Element | null;
    fullTravelObj: {
        countAdult: number,
        countChild: number,
        countInfant: number,
        TravelStatus: string
    }
    TravelStatus: string;
    anchorElClass: Element | null;
    error: string;
    countAdult: number;
    countChild: number;
    countInfant: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PackageSearchController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    scrollRef: RefObject<HTMLDivElement>;
    airportpSearchApiCallId = "";
    airportSearchpToApiCallId = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.scrollRef = React.createRef();
        // Customizable Area End

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            error: "",
            countAdult: 1,
            countChild: 0,
            countInfant: 0,
            anchorElClass: null,
            TravelStatus: "PremiumEconomy",
            fullTravelObj: {
                countChild: 0,
                countAdult: 1,
                countInfant: 0,
                TravelStatus: "PremiumEconomy"
            },
            setAnchorAi: null,
            activePicker: "",
            isOpen: false,
            endDate: null,
            startDate: null,
            toText: {
                state: "",
                country: "",
                flight: "",
                stateCode: ""
            },
            filteredItemsTo: [],
            searchField: "",
            currentIndex: 0,
            anchorEl: null,
            anchorElTo: null,
            inputSearch: "",
            inputSearchTo: "",
            searchText: {
                state: "",
                country: "",
                flight: "",
                stateCode: ""
            },
            filteredItems: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(

                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId != null) {
                if (apiRequestCallId === this.airportpSearchApiCallId && this.airportpSearchApiCallId != null) {
                    this.handleAirportSearchRes(responseJson);
                }
                if (apiRequestCallId === this.airportSearchpToApiCallId && this.airportSearchpToApiCallId != null) {
                    this.handleAirportSearchToRes(responseJson)
                }

            }
        }
        // Customizable Area End
    }



    // Customizable Area Start
    async componentDidMount() {
        if (this.scrollRef.current) {
            window.scrollTo(0, 0);
        }


    }
    handleClose = () => {
        this.setState({ anchorEl: null, anchorElTo: null, currentIndex: 0, searchField: "", filteredItems: [], filteredItemsTo: [] });
    };
    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index = 0) => {
        this.setState({ anchorEl: event.currentTarget, currentIndex: index });
        const { value } = event.target;
        this.setState({
            searchText: { ...this.state.searchText, state: value }, inputSearch: value
        }, () => {
            if (value !== "") {
                this.airportSearch(value);
            } else {
                this.setState({
                    filteredItems: [],
                    searchText: {
                        state: "",
                        country: "",
                        stateCode: "",
                        flight: ""
                    }
                });
            }
        });

    };
    airportSearch = (stateName: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.airportpSearchApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.airportpSearchEndPoint + stateName
        );

        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.offerApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleAirportSearchRes = (responseJson: any) => {
        if (responseJson.airport_list != null) {
            const bData = responseJson.airport_list.map((item: any, index: number) => {
                let flightName = item.Name.replace("Arpt", "Airport");
                return {
                    "id": `${index + 1}`,
                    "country": item.CountryCode,
                    "state": item.Code,
                    "flight": flightName,
                    "stateCode": item.CityCode
                }
            })
            const filteredItems = bData.filter((item: any) =>
                item.flight.toLowerCase().includes(this.state.searchText.flight.toLowerCase())
            );
            this.setState({
                searchText: {
                    ...this.state.searchText,
                    state: this.state.searchText.state
                },
                filteredItems
            });

        } else {
            this.setState({ searchText: { ...this.state.searchText, state: "" } })
        }
    }
    handleClick = (event: React.MouseEvent<HTMLElement>, index = 0) => {
        this.setState({
            currentIndex: index, searchText: {
                state: "",
                country: "",
                flight: "",
                stateCode: ""
            },
        });
    };
    handleSingleClose = (option: any) => {
        this.setState({ anchorEl: null, currentIndex: 0, searchText: option, inputSearch: "" });
    }
    reverseFunct = () => {
        this.setState({ toText: this.state.searchText, searchText: this.state.toText })
    }
    handleClickTo = (event: React.MouseEvent<HTMLElement>, index = 0) => {
        this.setState({
            inputSearchTo: '', toText: {
                state: "",
                country: "",
                stateCode: "",
                flight: ""
            }
        });
    };

    handleCloseTo = (selectedItem: any) => {
        this.setState({ anchorElTo: null, currentIndex: 0, toText: selectedItem, inputSearchTo: "" });
    };

    handleInputChangeTo = (event: React.ChangeEvent<HTMLInputElement>, index = 0) => {
        const { value } = event.target;
        this.setState({
            anchorElTo: event.currentTarget, currentIndex: index,
            toText: { ...this.state.toText, state: value }, inputSearchTo: value
        }, () => {
            if (value !== "") {
                this.airportSearchTo(value);
            } else {
                this.setState({
                    filteredItemsTo: [],
                    toText: {
                        state: "",
                        country: "",
                        stateCode: "",
                        flight: ""
                    }
                });
            }
        });


    };

    airportSearchTo = (stateName: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.airportSearchpToApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.airportpSearchEndPoint + stateName
        );

        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleAirportSearchToRes = (responseJson: any) => {
        if (responseJson.airport_list != null) {
            const bData = responseJson.airport_list.map((item: any, index: number) => {
                let flightName = item.Name.replace("Arpt", "Airport");
                return {
                    "id": `${index + 1}`,
                    "country": item.CountryCode,
                    "state": item.Code,
                    "flight": flightName,
                    "stateCode": item.CityCode
                }
            })
            const filteredItemsTo = bData.filter((item: any) =>
                item.flight.toLowerCase().includes(this.state.toText.flight.toLowerCase())
            );
            this.setState({
                toText: {
                    ...this.state.toText,
                    state: this.state.toText.state
                },
                filteredItemsTo
            });

        } else {
            this.setState({ toText: { ...this.state.toText, state: "" } })
        }

    }
    formatDate = (date: Date) => {
        if (date) {

            const dayaa = date.getDate();
            const month = date.toLocaleString('default', { month: 'short' });
            const year = date.getFullYear().toString().slice(-2);
            const weekday = date.toLocaleString('default', { weekday: 'long' });
            return `${dayaa} ${month}’${year} ${weekday}`;
        }
    };
    formatDatePart = (value: Date, part: string) => {
        let dateValue = this.formatDate(value)
        if (dateValue) {
            const parts = dateValue.split(' ');
            switch (part) {
                case "day":
                    return parts[0];
                case "month":
                    return parts[1];
                case "year":
                    return parts[2];
            }
        }
    };
    togglePicker = (event: React.MouseEvent<HTMLDivElement>, picker: string) => {
        this.setState({
            isOpen: true,
            activePicker: picker,
            setAnchorAi: event.currentTarget
        });
    };
    handleDateChange1 = (ranges: DateRange) => {
        if (this.state.activePicker === 'startDate') {
            let determinedDate = ranges.selection.startDate;

            this.setState({
                startDate: determinedDate,
                endDate: determinedDate,
            });
        }
    };
    handleCancel = () => {
        this.setState({ startDate: null, activePicker: "", endDate: null, isOpen: false, });
    };
    handleApply = () => {
        this.setState({ isOpen: false, activePicker: "", });
    };

    handleCloseCustomAi = () => {

        this.setState({
            setAnchorAi: null,
            isOpen: false,
        });
    }
    handleClickStatus = (buttonName: string) => {
        this.setState({
            TravelStatus: buttonName
        });
    };
    increaseCount = () => {
        this.setState(prevState => {
            const total = prevState.countChild + prevState.countInfant + prevState.countAdult;
            if (total < 9) {
                return {
                    countAdult: prevState.countAdult + 1,
                    error: ""
                };
            } else {
                this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
                return null;
            }
        });
    };

    decreaseCount = () => {
        this.setState(prevState => ({
            countAdult: prevState.countAdult === 1 ? prevState.countAdult : prevState.countAdult - 1,
            error: ""
        }));
    };
    handleClickClass = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ anchorElClass: event.currentTarget });
    };
    handleCloseClass = () => {
        this.setState({ anchorElClass: null });
    };
    errorFunct = (errorMsg: string) => {
        this.setState({
            error: errorMsg
        });
    };
    increaseCountChild = () => {
        this.setState(prevState => {
            const total = prevState.countChild + prevState.countInfant + prevState.countAdult;
            if (total < 9) {
                return {
                    countChild: prevState.countChild + 1,
                    error: ""
                };
            } else {
                this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
                return null;
            }
        });
    };

    decreaseCountChild = () => {
        this.setState(prevState => ({
            countChild: prevState.countChild === 0 ? prevState.countChild : prevState.countChild - 1,
            error: ""
        }));
    };

    increaseCountChildInfant = () => {
        this.setState(prevState => {
            const total = prevState.countChild + prevState.countInfant + prevState.countAdult;
            if (total < 9) {
                return {
                    countInfant: prevState.countInfant + 1,
                    error: ""
                };
            } else {
                this.errorFunct("If you want to add more than 9 travellers please contact email www.doolwa.com");
                return null;
            }
        });
    };

    decreaseCountChildInfant = () => {
        this.setState(prevState => ({
            countInfant: prevState.countInfant === 0 ? prevState.countInfant : prevState.countInfant - 1,
            error: ""
        }));
    };
    handleCancelTravel = () => {
        this.setState({
            TravelStatus: "PremiumEconomy", countAdult: 1, countChild: 0,
            fullTravelObj: {

                countAdult:
                    1,
                countChild: 0,
                countInfant: 0,
                TravelStatus: "PremiumEconomy"
            },
            anchorElClass: null,
            error: ""
        })
    }
    handleDone = () => {
        this.setState({
            fullTravelObj: {
                countAdult: this.state.countAdult,
                countChild: this.state.countChild,
                countInfant: this.state.countInfant,
                TravelStatus: this.state.TravelStatus
            },
            anchorElClass: null
        })
    }
    handleNavigate = (itemKey: string) => {
        this.props.navigation.navigate(itemKey);
      }
    // Customizable Area End
}
