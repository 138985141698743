import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import {loaderGif} from "../../blocks/landingpage/src/assets"

interface myProps {
  loading: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgb(0, 0, 0, .4)",
    zIndex: 9999,
    height:"100%"
  },
  circularContainer: {
    position: "absolute",
    left: "50%",
    top: "40%",
    transform: "translate(-50%, -50%)",
  },
}));

export default function Loader(props: myProps) {
  const classes = useStyles();
  const { loading } = props;
  useEffect(() => {
    if (loading) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
    
    return () => {
      document.body.style.overflowY = "auto";
    };
  }, [loading]);
  return props.loading ? (
    <div className={classes.root}>
      <div className={classes.circularContainer}>
        {/* <CircularProgress /> */}
        <img src={loaderGif} width={100} />
      </div>
    </div>
  ) : (
    <div />
  );
}
